<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="สมัครสมาชิก"
      next-button-text="ถัดไป"
      back-button-text="ย้อนกลับ"
      class="mb-3 "
      steps-classes="d-none d-sm-flex"
      @on-complete="formSubmitted"
    >
      <!-- accoint details tab -->
      <tab-content title="เบอร์โทรศัพท์" :before-change="validationForm">
        <validation-observer ref="registerRules" tag="form">
          <b-row>
            <b-col class="mb-2">
              <b-alert variant="warning" show class="mt-1">
                <div class="alert-body">
                  <feather-icon icon="InfoIcon" class="mr-50" />
                  กรุณาระบุเบอร์โทรศัพท์ที่ใช้ให้ถูกต้องเพื่อรับ SMS
                  ยืนยันรายการ
                </div>
              </b-alert>
              <b-alert ref="alert-message" :show="phoneExist" variant="danger">
                <div class="alert-body">
                  <feather-icon icon="InfoIcon" class="mr-50" />
                  {{ `หมายเลขโทรศัพท์ที่กรอกได้ถูกใช้งานไปแล้ว` }}
                  <a :to="{ name: 'login' }" class="text-blue-400 underline"
                    >เข้าสู่ระบบ</a
                  >
                  คลิก
                </div>
              </b-alert>
            </b-col>
            <b-col sm="12">
              <input-field
                label="เบอร์โทรศัพท์"
                v-model="form.step1.phone"
                id="phone"
                :red="true"
                placeholder="กรุณาระบุเบอร์โทร กรอกเฉพาะตัวเลขเท่านั้นเช่น 0928882345"
                :rules="'required|numeric|digits:10'"
                errMessage="กรุณาระบุเบอร์โทรศัพท์ให้ถูกต้อง"
              />
            </b-col>
            <b-col sm="12">
              <canvas
                ref="captchaImage"
                width="90"
                height="50"
                style="border:1px solid white; background-color: white; border-radius: 4px"
              />
            </b-col>
            <b-col sm="12">
              <input-field
                label="รหัสลับ"
                id="captcha"
                :red="true"
                placeholder="กรุณาระบุรหัสลับตามภาพที่เห็น"
                :rules="`required|is:${code}`"
                errMessage="กรุณาระบุรหัสลับให้ถูกต้อง"
              />
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- personal details tab -->
      <tab-content title="ข้อมูลส่วนตัว" :before-change="validationFormInfo">
        <validation-observer ref="infoRules" tag="form">
          <b-row>
            <b-col sm="12" class="mb-2">
              <b-alert variant="warning" show class="mt-1">
                <div class="alert-body">
                  <feather-icon icon="InfoIcon" class="mr-50" />
                  กรุณาระบุชื่อ-นามสกุลให้ตรงกับบัญชีที่ใช้
                  หากชื่อ-นามกุลไม่ตรงกับบัญชีจะไม่สามารถถอนเงินได้
                </div>
              </b-alert>
              <b-alert
                ref="alert-message"
                :show="accountNoExist"
                variant="danger"
              >
                <div class="alert-body">
                  <feather-icon icon="InfoIcon" class="mr-50" />
                  {{ `หมายเลขบัญชีที่กรอก ได้ถูกใช้งานไปแล้ว` }}
                  <a :to="{ name: 'login' }" class="text-blue-400 underline"
                    >เข้าสู่ระบบ</a
                  >
                  คลิก
                </div>
              </b-alert>
            </b-col>
            <b-col sm="6">
              <input-field
                label="ขืื่อจริง"
                v-model="form.step2.firstname"
                id="firstname"
                placeholder="กรุณาระบุชื่อจริง"
                :rules="'required'"
                :red="true"
                errMessage="กรุณาระบุชื่อจริง"
              />
            </b-col>
            <b-col sm="6">
              <input-field
                label="นามสกุล"
                v-model="form.step2.lastname"
                id="lastname"
                placeholder="กรุณาระบุนามสกุล"
                :rules="'required'"
                :red="true"
                errMessage="กรุณาระบุนามสกุล"
              />
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                name="Banks"
                rules="required"
              >
                <b-form-group
                  label="ธนาคาร"
                  label-for="bank"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="bank"
                    v-model="form.step2.bank"
                    :options="banks"
                    :selectable="
                      option => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] ? 'กรุณาเลือกธนาคาร' : '' }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <input-field
                label="เลขที่บัญชี"
                :red="true"
                v-model="form.step2.accountNo"
                id="bank_account_no"
                placeholder="กรุณาเลขบัญชีเฉพาะตัวเลขเท่านั้น"
                :rules="'required|numeric|min:10|max:12'"
                errMessage="กรุณาระบุเลขที่บัญชีให้ถูกต้อง"
              />
            </b-col>
            <b-col sm="6" v-if="isSCB">
              <input-field
                label="ขืื่อจริงภาษาอังกฤษ"
                v-model="form.step2.firstname_en"
                id="firstname_en"
                placeholder="กรุณาระบุชื่อจริงเช่น Prayuth"
                :rules="'required'"
                :red="true"
                errMessage="กรุณาระบุชื่อจริงเป็นภาษาอังกฤษ"
              />
            </b-col>
            <b-col sm="6" v-if="isSCB">
              <input-field
                label="นามสกุลภาษาอังกฤษ"
                v-model="form.step2.lastname_en"
                id="lastname_en"
                placeholder="กรุณาระบุนามสกุลเช่น WanJun"
                :rules="'required'"
                :red="true"
                errMessage="กรุณาระบุนามสกุลเป็นภาษาอังกฤษ"
              />
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- address  -->
      <tab-content title="กำหนดรหัสผ่าน" :before-change="validationFormAddress">
        <validation-observer ref="addressRules" tag="form">
          <b-row>
            <b-col md="6">
              <input-field
                label="กำหนดรหัสผ่าน"
                :red="true"
                v-model="form.step3.password"
                id="password"
                placeholder="กรุณากำหนดรหัสผ่าน 6-16 ตัวอักษร"
                :rules="'required|min:6|max:16'"
                errMessage="กรุณาตั้งรหัสผ่าน 6-16 ตัว"
              />
            </b-col>
            <b-col md="6">
              <input-field
                label="LINE ID"
                :red="true"
                v-model="form.step3.lineId"
                id="lineId"
                placeholder="กรุณาระบุ LINE ID"
                :rules="'required'"
                errMessage="กรุณากรอกไลน์ไอดีเพื่อรับการแจ้งเตือน"
              />
            </b-col>
            <b-col v-show="hasSource ? false : true" md="6">
              <validation-provider
                #default="{ errors }"
                name="Channel"
                rules="required"
              >
                <b-form-group
                  label="ช่องทางที่รู้จักเรา"
                  label-for="channel"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="channel"
                    v-model="form.step3.channel"
                    :options="channels"
                    :selectable="
                      option => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] ? 'กรุณาเลือกช่องทางที่รู้จักเรา' : '' }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Promotion"
                rules="required"
              >
                <b-form-group
                  label="โปรโมชั่น"
                  label-for="promotion"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="promotion"
                    v-model="form.step3.promotion"
                    :options="promotions"
                    :selectable="
                      option => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] ? 'กรุณาเลือกโปรโมชั่น' : '' }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- <b-col md="12">
              <input-field
                label="รหัสโปรโมชั่น"
                v-model="form.step3.promoCode"
                id="promoCode"
                placeholder="กรุณาระบุรหัสโปรโมชั่น"
                :rules="''"
              />
            </b-col> -->
            <b-col md="12">
              <b-alert
                ref="alert-message"
                :show="
                  form.step3.promotion != null &&
                    form.step3.promotion.turnover > 0
                "
                variant="warning"
              >
                <div class="alert-body">
                  <feather-icon icon="InfoIcon" class="mr-50" />
                  เงื่อนไขการรับโปรชั่น: {{ form.step3.promotion.text }} <br />
                  ต้องทำเทิร์นครบ
                  <span style="color:red">{{
                    form.step3.promotion.turnover
                  }}</span>
                  เทิร์น ลูกค้าถึงจะสามารถถอนเงินได้ในครั้งแรก <br />
                  <br />
                  ตัวอย่างเช่นลูกค้าฝากเงิน 100 บาท
                  ยอดเทิร์นที่ต้องทำก่อนถอนเงินคือ
                  {{
                    form.step3.promotion.value == '8'
                      ? 200 * form.step3.promotion.turnover
                      : 100 * form.step3.promotion.turnover
                  }}
                  บาท
                  <br />
                  <br />
                  {{
                    form.step3.promotion.value == '8'
                      ? '** โบนัสจะถูกหักคืนเมื่อถอนเงิน **'
                      : '** ถอนโบนัสได้สูงสุด 100 บาท **'
                  }}
                  <br /><br />
                  {{
                    form.step3.promotion.value == '8'
                      ? '*** โบนัสต้องนำไปเล่นเกมส์สล็อตเท่านั้น ***'
                      : ''
                  }}
                  <br />
                  ยอดเทิร์น คือยอดเดิมพันรวม เช่นเดิม เดิมพันหรือหมุนสล็อต รอบละ
                  20 บาท 3 รอบ จำนวนเทิร์นที่นับคือ 60
                </div>
              </b-alert>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInvalidFeedback,
  BAlert
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { codeIcon } from './code'
import InputField from './InputField.vue'

import jwt from '../../auth/jwt/useJwt'

import { setUserData } from '../../auth/utils'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormInvalidFeedback,
    InputField,
    BAlert,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  data() {
    return {
      form: {
        step1: {
          phone: '',
          captcha: ''
        },
        step2: {
          accountNo: '',
          bank: '',
          firstname: '',
          lastname: '',
          firstname_en: '',
          lastname_en: ''
        },
        step3: {
          password: '',
          lineId: '',
          channel: '',
          promoCode: '',
          promotion: '',
          cid: '',
          ref: ''
        }
      },

      code: '',
      selectedBank: '',
      phoneExist: false,
      hasSource: false,
      accountNoExist: false,
      selectedPromotion: '',
      selectedChannel: '',
      selectedLanguage: '',
      name: '',
      emailValue: '',
      PasswordValue: '',
      passwordCon: '',
      first_name: '',
      last_name: '',
      address: '',
      landMark: '',
      pincode: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      required,
      email,
      codeIcon,
      isSCB: false,
      banks: [
        { value: 'select_value', text: 'กรุณาเลือกธนาคาร' },
        { value: '1', text: 'ธนาคารกรุงเทพ' },
        { value: '2', text: 'ธนาคารกรุงศรีอยุธยา' },
        { value: '3', text: 'ธนาคารกรุงไทย' },
        { value: '4', text: 'ธนาคารกสิกรไทย' },
        { value: '5', text: 'ธนาคารไทยพาณิชย์' },
        { value: '6', text: 'ธนาคารทหารไทย' },
        { value: '7', text: 'ธนาคารออมสิน' },
        { value: '8', text: 'ธนาคารธนชาต' },
        { value: '9', text: 'ธนาคารเพื่อการเกษตรและสหกรณ์์' },
        { value: '10', text: 'ธนาคารยูโอบี' },
        { value: '11', text: 'ธนาคารซีไอเอ็มบี' },
        { value: '12', text: 'ธนาคารธนาคารอาคารสงเคราะห์' },
        { value: '13', text: 'ธนาคารอิสลามแห่งประเทศไทย' },
        { value: '14', text: 'ธนาคารทิสโก้' },
        { value: '15', text: 'ธนาคารซิตี้แบงก์' },
        { value: '16', text: 'ธนาคารเกียรตินาคิน' },
        { value: '17', text: 'ธนาคารสแตนดาร์ดชาร์เตอร์' },
        { value: '18', text: 'ธนาคารแลนด์ แอนด์ เฮ้าส์' }
      ],
      promotions: [
        { value: 'select_value', text: 'เลือกโปรโมชั่น' },
        { value: '0', text: 'ไม่รับ' }
      ],
      channels: [{ value: 'select_value', text: 'รู้จักเราจากช่องทางไหน' }]
    }
  },
  watch: {
    'form.step2': {
      handler(val) {
        if (val.bank.value === '5') {
          this.isSCB = false
        } else {
          this.isSCB = false
        }
      },
      deep: true
    }
  },
  mounted() {
    this.createCaptcha()
    if (typeof this.$route.query.s !== 'undefined') {
      console.log(this.$route.query.s)
      this.form.step3.channel = { value: this.$route.query.s }
      this.hasSource = true
    }

    if (typeof this.$route.query.ref !== 'undefined') {
      console.log(this.$route.query.ref)
      this.form.step3.ref = { value: this.$route.query.ref }
    }
    console.log(window.location.hostname)
  },
  async beforeMount() {
    if (typeof this.$route.query.cid !== 'undefined') {
      console.log(this.$route.query.cid)
      this.form.step3.cid = this.$route.query.cid
    }
    await this.getChannels()
    await this.getPromotions()
  },
  methods: {
    createCaptcha() {
      const charsArray = '123456789'
      const lengthOtp = 4
      const captcha = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < lengthOtp; i++) {
        // below code will not allow Repetition of Characters
        const index = Math.floor(Math.random() * charsArray.length + 1) // get the next character from the array
        if (captcha.includes(charsArray[index]) === false)
          captcha.push(charsArray[index])
        // eslint-disable-next-line no-plusplus
        else i--
      }
      const canvas = this.$refs.captchaImage

      const ctx = canvas.getContext('2d')

      if (ctx) {
        ctx.font = '25px Georgia'
        ctx.strokeText(captcha.join(''), 0, 30)
        // storing captcha so that can validate you can save it somewhere else according to your specific requirements
        this.code = captcha.join('')
        this.captchaHTML = ctx
      }

      // adds the canvas to the body element
    },
    async checkInfo(fieldName, fieldValue) {
      const resp = await this.$http.post('/internal/check/info', {
        domain: 'betded888.com',
        fieldName,
        fieldValue
      })

      return resp.data
    },

    // info
    info() {
      this.$swal({
        title: 'กรุณารอสักครู่',
        text: 'ระบบกำลังทำการสมัครสมาชิก..',
        icon: 'info',
        allowOutsideClick: false, // Prevent closing when clicking outside
        showCancelButton: false, // Hide the cancel button
        showConfirmButton: false // Hide the confirm button
      })
    },

    async formSubmitted() {
      this.info()
      const resp = await this.$http.post('/internal/register', {
        domain: 'betded888.com',
        payload: this.form
      })

      if (resp.status === 201) {
        this.$swal.close()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'สมัครสมาชิกเสร็จเรียบร้อย',
            icon: 'EditIcon',
            variant: 'success'
          }
        })
        jwt.setToken(resp.data.token.token)
        setUserData(resp.data.member)
        this.$router.push('/member/lobby')
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'พบข้อผิดพลาดกรุณาติดต่อแอดมิน',
            icon: 'EditIcon',
            variant: 'danger'
          }
        })
      }
    },
    async validationForm() {
      const isValid = await this.$refs.registerRules.validate()
      if (isValid) {
        const isExist = await this.checkInfo('phone_no', this.form.step1.phone)
        if (isExist.status === true) {
          this.phoneExist = true
          return false
        }
        return true
      }
      return false
    },
    async validationFormInfo() {
      const isValid = await this.$refs.infoRules.validate()
      if (isValid) {
        const isExist = await this.checkInfo(
          'bank_no',
          this.form.step2.accountNo
        )
        if (isExist.status === true) {
          this.accountNoExist = true
          return false
        }
        return true
      }
      return false
    },
    async getChannels() {
      const channels = await this.$http.get('/internal/channel/8')
      channels.data.forEach(channel => {
        this.channels.push({
          value: `${channel.id}`,
          text: channel.name
        })
      })
    },
    async getPromotions() {
      const promotions = await this.$http.get('/internal/promotions')
      promotions.data.forEach(promotion => {
        this.promotions.push({
          value: `${promotion.id}`,
          text: promotion.title,
          turnover: promotion.turnover
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    }
  }
}
</script>

<template>
  <b-form-group :label="label" :label-for="id">
    <template v-slot:label>
      {{ label }} <span v-show="red" class="text-danger">*</span>
    </template>
    <validation-provider #default="{ errors }" :name="id" :rules="rules">
      <b-form-input
        :id="id"
        v-model="value"
        :state="errors.length > 0 ? false : null"
        :placeholder="placeholder"
        @input="$emit('input', value)"
        autocomplete="off"
      />
      <small class="text-danger">{{ errors[0] ? errMessage : '' }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

import { BFormGroup, BFormInput } from 'bootstrap-vue'

import { required, numeric, digits } from '@validations'

export default {
  components: {
    ValidationProvider,
    BFormGroup,
    BFormInput
  },
  props: {
    label: {
      type: String,
      default: '',
      required: true
    },
    id: {
      type: String,
      default: '',
      required: true
    },
    placeholder: {
      type: String,
      default: '',
      required: true
    },
    rules: {
      type: String,
      default: '',
      required: true
    },
    red: {
      type: Boolean,
      default: false,
      required: false
    },
    errMessage: {
      type: String,
      default: '',
      required: false
    }
  },
  data() {
    return {
      value: '',
      selected: '',

      required,
      numeric,
      digits
    }
  }
}
</script>

<template>
  <div class="block">
    <b-container class="bv-example-row ">
      <b-row class="justify-content-md-center">
        <b-col cols="12" sm="4"
          ><div class="my-4"><logo /></div
        ></b-col>
      </b-row>
    </b-container>
    <b-container class="bv-example-row ">
      <b-row class="justify-content-md-center">
        <b-col cols="12" sm="9">
          <form-wizard autocomplete="off" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { BRow, BCol, BContainer } from 'bootstrap-vue'

import FormWizard from './component/FormWizard.vue'
import Logo from './component/Logo.vue'

export default {
  components: {
    BRow,
    BCol,
    FormWizard,
    Logo,
    BContainer
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
